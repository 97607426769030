import { ThemeOptions, createTheme } from '@mui/material/styles';

import componentStyleOverrides from './compStyleOverride';
import themePalette from './palette';
import themeTypography from './typography';


export const theme = () => {

    const themeOptions: ThemeOptions = {
        direction: 'ltr',
        palette: themePalette(),
        typography: themeTypography(),
        components: componentStyleOverrides(),
        mixins: {
            toolbar: {
                minHeight: '48px',
                padding: '16px',
                '@media (min-width: 600px)': {
                    minHeight: '48px'
                }
            }
        },
    };

    const themes = createTheme(themeOptions);

    return themes;
};

export default theme;
