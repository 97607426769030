import users from "./users";
import { MenuType } from "./types";
import transactions from "./transactions";
import contacts from "./contacts";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: {
  items: MenuType[];
} = {
  items: [users, transactions, contacts],
};
export default menuItems;
