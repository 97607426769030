import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

const UsersList = Loadable(
  lazy(() => import("views/users/containers/UsersList"))
);
const TransactionsList = Loadable(
  lazy(() => import("views/transactions/containers/TransactionsList"))
);
const ContactUsList = Loadable(
  lazy(() => import("views/contactUs/containers/ContactUsList"))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <MainLayout />,
  children: [
    {
      path: "/users",
      element: <UsersList />,
    },
    {
      path: "/transactions",
      element: <TransactionsList />,
    },
    {
      path: "/contacts",
      element: <ContactUsList />,
    },
  ],
};

export default MainRoutes;
