import logo from "../assets/images/logo.svg";

const Logo = ({ width = "100" }: { width?: string }) => {
  return (
    <img
      width={width}
      height={"auto"}
      style={{ objectFit: "contain" }}
      src={logo}
      alt="S for Salimpay"
    />
  );
};

export default Logo;
