// assets
import { IconMessage } from "@tabler/icons";
import { MenuType } from "./types";

// constant
const icons = { IconMessage };

// ==============================|| Customers MENU ITEMS ||============================== //

const contacts: MenuType = {
  id: "contacts",
  title: "",
  type: "group",
  children: [
    {
      id: "contacts",
      title: "Contacts",
      type: "item",
      url: "/contacts",
      icon: icons.IconMessage,
      breadcrumbs: false,
    },
  ],
};

export default contacts;
