import "./App.css";

import "assets/scss/style.scss";
import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material";
import themes from "./themes";
import Routes from "routes";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";

import { SnackbarProvider } from "notistack";
const queryClient = new QueryClient();

function App() {
  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        horizontal: "right",
        vertical: "bottom",
      }}
    >
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        <ThemeProvider theme={themes()}>
          <QueryClientProvider client={queryClient}>
            <Routes />
          </QueryClientProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </SnackbarProvider>
  );
}

export default App;
