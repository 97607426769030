
import * as actionTypes from './types';

export const initialState = {
    token: ""

};


const authReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case actionTypes.LOG_IN:
            return {
                ...state,
                token: action.payload

            };
        case actionTypes.LOG_OUT:
            return {
                ...state,
                token: ''
            };

        default:
            return state;
    }
};

export default authReducer;
