import colors from '../assets/scss/_themes-vars.module.scss';

const color = colors;

export const theme = {
    colors: color,
    heading: color.grey900,
    paper: color.paper,
    backgroundDefault: color.paper,
    background: color.primaryLight,
    darkTextPrimary: color.grey700,
    darkTextSecondary: color.grey500,
    textDark: color.grey900,
    menuSelected: color.paper,
    menuSelectedBack: color.primaryMain,
    divider: color.grey200,
    customization: {
        fontFamily: `'Roboto', sans-serif`,
        borderRadius: 12

    }

};