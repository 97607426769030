import { applyMiddleware, createStore } from 'redux';
import reducer from './reducer';
import { save, load } from "redux-localstorage-simple"

// ==============================|| REDUX - MAIN STORE ||============================== //
const createStoreWithMiddleware
    = applyMiddleware(
        save() // Saving done here
    )(createStore)
const persister = 'Free';
const store = createStoreWithMiddleware(
    reducer,
    load() // Loading done here
)
export { store, persister };
export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>
