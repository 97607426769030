import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
const SignIn = Loadable(lazy(() => import("views/auth/containers/SignIn")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
  path: "/",
  element: <MinimalLayout />,
  children: [
    {
      path: "/sign-in",
      element: <SignIn />,
    },
  ],
};

export default AuthenticationRoutes;
